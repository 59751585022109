import React from 'react'
import { Link } from 'gatsby'

interface ButtonShellProps {
  isAnchor?: boolean
  to: string
  children: any
  className?: string
  target?: string
  rel?: string
}

const ButtonShell: React.FC<ButtonShellProps> = ({
  isAnchor = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  let isAnchorNew = isAnchor

  if (
    to.indexOf('tel:') !== -1 ||
    to.indexOf('mailto:') !== -1 ||
    to.indexOf('/static/') !== -1 ||
    to.indexOf('https://') !== -1
  ) {
    isAnchorNew = true
  }

  return !isAnchorNew ? (
    <Link to={to} className={className} target={target}>
      {children}
    </Link>
  ) : (
    <a href={to} className={className} target={target}>
      {children}
    </a>
  )
}

export default ButtonShell
