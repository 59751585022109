import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'

// CSS
import 'styles/all.css'

// Images
import BodyBG from 'img/bodybg.inline.svg'

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: boolean) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  @media (min-width: 576px) {
    padding-top: 50px;
  }
  @media (max-width: 575px) {
    padding-top: 30px;
  }
`

const BackgroundWrapper = styled.div`
  & .container {
    max-width: 2800px !important;
  }
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  & svg {
    @media (max-width: 1399px) {
      height: 100%;
    }
  }

  @media (min-width: 576px) {
    top: 220px;
  }
  @media (max-width: 575px) {
    top: 300px;
  }
`

interface LayoutProps {
  children: any
  locale?: string
  translations?: any
}

const Layout: React.FC<LayoutProps> = ({ children, locale, translations }) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      {/* @ts-ignore */}
      <LayoutContext.Provider value={{ setIsScrollable, locale, translations }}>
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky} style={{ minHeight: '100vh' }}>
          <BackgroundWrapper>
            <div className="container d-flex justify-content-center position-relative">
              <Background>
                <BodyBG />
              </Background>
            </div>
          </BackgroundWrapper>

          <div className="position-relative" style={{ zIndex: 2 }}>
            {children}
          </div>
        </Content>
        <div className="position-relative" style={{ zIndex: 2 }}>
          <Footer />
        </div>
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
