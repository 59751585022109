const mainTheme = {
  color: {
    primary: '#12155F',
    secondary: '#E9C080',
    secondaryDark: '#AA7C3E',
    grey: '#D1D4DD',
    dark: '#000000',
    light: '#FFFFFF',
    gradient: {
      from: '233, 192, 128',
      to: '170, 124, 62',
    },
  },

  font: {
    family: {
      primary: 'agenda, sans-serif',
      secondary: 'Abhaya Libre, serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '20px',
      post: '22px',
      huge: '30px',
      heading: '40px',
      quote: '50px',
      mega: '60px',
      giga: '75px',
    },

    weight: {
      ultraLight: 100,
      light: 300,
      regular: 400,
      semiBold: 500,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
