import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;
    font-size: ${({ theme }) => theme.font.size.large} !important;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-family: ${({ theme }) => theme.font.family.secondary};
    margin-bottom: 1.5rem;
    line-height: 20px;
    &:before {
      content: '';
      position: absolute;
      width: 70%;
      height: 3px;
      bottom: -15px;
      left: 0;
      ${({ theme }) => css`
        background: ${theme.color.gradient.to};
        background: ${`rgb(${theme.color.gradient.to})`};
        background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.from}, 1) 0%, rgba(${theme.color.gradient.to}, 1) 100%);`};
      `};
      transition: all 0.3s ease-in-out;
    }
  }

  & p,
  & li {
    font-size: ${({ theme }) => theme.font.size.big};
    line-height: ${({ theme }) => theme.font.size.huge};
  }

  & a {
    text-decoration: none;
    &:hover > span {
      text-decoration: underline;
    }
  }

  & img {
    min-width: 15px;
    min-height: 15px;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            column
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          {fields?.footer?.menuFooter?.map(({ column }: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col-xl col-lg-4 col-sm-6 " key={index}>
              <Content content={column} />
            </div>
          ))}
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
