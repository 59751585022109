import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryCSS = css`
  ${({ theme }) => css`
    background: ${theme.color.gradient.to};
    background: ${`rgb(${theme.color.gradient.to})`};
    background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.from}, 1) 0%, rgba(${theme.color.gradient.to}, 1) 100%);`};
  `};
  display: inline-flex;
  align-items: center;
  text-align: center;
  min-width: 165px !important;
  min-height: 34px;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.primary} !important;
    font-weight: ${(props) => props.theme.font.weight.bold} !important;
    font-size: 14px;
    padding: 2px 13px !important;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
  }
`

const StyledButton = styled(motion.span)`
  ${ButtonPrimaryCSS};
`

const PhoneButton = styled(motion.span)`
  ${ButtonPrimaryCSS};
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  const [telephoneButton, setTelephoneButton] = React.useState(false)
  const [flipSideContent, setFlipSideContent] = React.useState('')
  const [phoneHover, setPhoneHover] = React.useState(false)
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    // @ts-ignore
    const anchor = inputRef.current?.children[0]

    if (anchor.href && anchor.href.includes('tel:')) {
      const split = anchor.href.split('tel:', 20)

      setFlipSideContent(split[1])
      setTelephoneButton(true)
    }
  }, [])

  return (
    <>
      <StyledButton
        className={className}
        initial={{
          background:
            'linear-gradient(90deg,rgba(233, 192, 128, 1) 0%,rgba(170, 124, 62, 1) 50%)',
        }}
        whileHover={{
          background:
            'linear-gradient(90deg,rgba(233, 192, 128, 1) 20%,rgba(170, 124, 62, 1) 80%)',
        }}
        transition={{ type: 'spring' }}
        ref={inputRef}
      >
        {isCustom ? (
          children
        ) : (
          <ButtonShell to={to} isAnchor={isAnchor} target={target}>
            {children}
          </ButtonShell>
        )}
      </StyledButton>
      {/* {telephoneButton && (
        <StyledButton
          onHoverStart={() => setPhoneHover(true)}
          onHoverEnd={() => setPhoneHover(false)}
          className={className}
          initial={{
            background:
              'linear-gradient(90deg,rgba(233, 192, 128, 1) 0%,rgba(170, 124, 62, 1) 50%)',
          }}
          whileHover={{
            background:
              'linear-gradient(90deg,rgba(233, 192, 128, 1) 20%,rgba(170, 124, 62, 1) 80%)',
          }}
          transition={{ type: 'spring' }}
          ref={inputRef}
        >
          <ButtonShell to={to} isAnchor={isAnchor} target={target}>
            {phoneHover ? flipSideContent : children}
          </ButtonShell>
        </StyledButton>
      )} */}
    </>
  )
}

export default ButtonPrimary
