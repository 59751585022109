/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import { useMedia } from 'use-media'

// Images
// import Logo from 'img/logo.inline.svg'
import Logo from 'components/elements/Svg/Logo'
import ArrowDown from 'img/arrowdown.inline.svg'
import BodyBG from 'img/bodybg.inline.svg'
import HoverEffect from 'img/hovereffect.svg'

// Components
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'


const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: ${(props) =>
    props.isSticky ? props.theme.color.primary : 'transparent'};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 7px rgba(0, 0, 0, 1);
    `}

  ${(props) =>
    !props.isSticky &&
    css`
      background: rgb(18, 21, 95);
      background: linear-gradient(
        180deg,
        rgba(18, 21, 95, 1) 20%,
        rgba(18, 21, 95, 0.01469537815126055) 100%
      );
    `}

  @media (min-width: 576px) {
    height: 156px;
  }
  @media (max-width: 575px) {
    height: 116px;
  }
`
const TopmenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};

  & ul {
    padding-left: unset;
    list-style-type: none;
    margin-bottom: 0;
  }
`

const TopmenuContainer = styled.div`
  display: flex;
  @media (min-width: 576px) {
    height: 50px;
  }
  @media (max-width: 575px) {
    height: 30px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 576px) {
    height: 111px;
  }
  @media (max-width: 575px) {
    height: 91px;
  }
`

const Brand = styled(NavLink)`
  font-size: 0;
  & > svg {
    height: 66px;
    width: 127px;
    /* transition: all 1s ease-in-out; */

    /* & path {
      @media (min-width: 992px) {
        fill: ${({ theme }) => theme.color.light};
      }
      @media (max-width: 991px) {
        & #stop-1 {
          stop-color: #e9c080 !important;
        }
        & #stop-2 {
          stop-color: #aa7c3e !important;
        }
      }
    } */

    /* & path:first-child {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      & path:first-child {
        fill: ${({ theme }) => theme.color.secondary} !important;
      }
    } */

    @media (max-width: 1199px) {
      height: 46px;
      width: 107px;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            submenu {
              link {
                title
                url
              }
            }
            link {
              title
              url
            }
          }
          topMenuHeader {
            menuleft {
              link {
                title
                url
              }
              linktextmobile
            }
            menuright {
              description
            }
          }
        }
      }
    }
  `)

  const hoverHandler = () => {
    setHover(true)

    setTimeout(() => {
      setHover(false)
    }, 690)
  }

  const [hover, setHover] = useState(false)

  return (
    <StyledHeader isSticky={isSticky}>
      <TopmenuWrapper>
        <TopmenuContainer className="container">
          <Topmenu fields={fields} />
        </TopmenuContainer>
      </TopmenuWrapper>
      <Container className="container">
        <motion.div
          onHoverStart={() => hoverHandler()}
          onHoverEnd={() => setHover(false)}
        >
          <Brand to="/">
            <Logo hovered={hover} />
            Borg OSC
          </Brand>
        </motion.div>

        <MenuMobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <MenuDesktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;
  background: ${({ theme }) => theme.color.primary};
  overflow: scroll;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 80%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: 24px;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MobileBG = styled.div`
  & svg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    opacity: 0.45;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }
`

const BottomMenu = styled.div`
  position: relative;
  z-index: 2;

  & p {
    @media (max-width: 575px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

interface MenuMobileProps {
  fields: any
  isSticky?: boolean
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <MobileBG className="position-absolute d-flex d-lg-none">
            <BodyBG />
          </MobileBG>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
          <BottomMenu className="d-flex justify-content-center mt-5  ">
            <ParseContent
              content={fields.header.topMenuHeader.menuright.description}
            />
          </BottomMenu>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
`

interface MenuDesktopProps {
  fields: any
  isSticky?: boolean
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled(motion.li)`
  min-height: 32px;
  & > a {
    position: relative;
    color: ${(props) => props.theme.color.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    @media (max-width: 1399px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }

    &:after {
      content: url(${HoverEffect});
      position: absolute;
      top: -25px;
      width: 32px;
      height: 20px;

      visibility: hidden;
      left: calc(50% - 16px);
      @media (max-width: 991px) {
        top: -5px;
      }
    }

    &[aria-current] {
      &:after {
        visibility: visible;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.light} !important;
      &:after {
        visibility: visible;
      }
    }
  }
  @media (min-width: 1400px) {
    margin-left: 50px;
  }
  @media (max-width: 1399px) {
    margin-left: 20px;
  }
  @media (max-width: 991px) {
    margin-left: 0;
  }
`

const DisabledItem = styled(motion.li)`
  min-height: 32px;
  & > a {
    position: relative;
    color: ${(props) => props.theme.color.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    @media (max-width: 1399px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }

    &:after {
      content: url(${HoverEffect});
      position: absolute;
      top: -20px;
      width: 32px;
      height: 20px;
      visibility: hidden;
      left: calc(50% - 16px);
      @media (max-width: 991px) {
        top: -5px;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.light};
    }

    &[aria-current] {
      &:after {
        visibility: visible;
      }
    }
  }
  @media (min-width: 1400px) {
    margin-left: 50px;
  }
  @media (max-width: 1399px) {
    margin-left: 20px;
    margin-top: 3px;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    margin-top: 0;
  }
`

const StyledNavLink = styled(NavLink)`
  position: relative;
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  transition: all 0.4s ease-in-out;

  &[aria-current] {
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.light} !important;
  }
`

const SubmenuWrapper = styled(motion.div)`
  position: absolute;
  z-index: 4;
  top: 10px;
  min-width: 200px;
  box-shadow: 1px 1px 12px 0px #404040;
  overflow-y: scroll;

  @media (min-width: 992px) {
    left: -36px;
  }
  @media (max-width: 991px) {
    left: calc(50% - 167px);
    top: 20px;
  }
`

const SubmenuPointer = styled(motion.div)`
  position: relative;
  top: -2px;
  line-height: 0;
  &:hover {
    cursor: pointer;
  }
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [submenuHover, setSubmenuHover] = useState<number | null>(null)

  const subMenuAnimations = {
    enter: {
      opacity: 1,
      rotateX: 0,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      display: 'block',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <>
      {fields.header.menuHeader.map(({ link, submenu }: any, index: number) => {
        const hovered: boolean = submenuHover === index

        if (submenu) {
          return (
            <React.Fragment
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <DisabledItem
                className="d-flex flex-lg-column justify-content-center align-items-center position-relative"
                onMouseEnter={() => setSubmenuHover(index)}
                onMouseLeave={() => setSubmenuHover(null)}
                whileTap={() => setSubmenuHover(index)}
                onTapCancel={() => setSubmenuHover(null)}
              >
                <NavLink
                  to={link.url}
                  // onClick={(e: any) => e.preventDefault()}
                >
                  {link.title}
                </NavLink>
                <SubmenuPointer className="ms-2 ms-lg-0">
                  <ArrowDown />

                  <SubmenuWrapper
                    initial="exit"
                    animate={hovered ? 'enter' : 'exit'}
                    variants={subMenuAnimations}
                  >
                    <Submenu items={submenu} />
                  </SubmenuWrapper>
                </SubmenuPointer>
              </DisabledItem>
            </React.Fragment>
          )
        }
        return (
          <Item
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <NavLink to={link.url}>{link.title}</NavLink>
          </Item>
        )
      })}
    </>
  )
}

const TopmenuList = styled.ul`
  & li {
    &:first-child {
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light};
    }
    @media (max-width: 399px) {
      font-size: 12px;
    }
  }
`

interface TopmenuProps {
  fields: any
}

const Topmenu: React.FC<TopmenuProps> = ({ fields }) => {
  const isMobile = useMedia({ maxWidth: 1199 })

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <TopmenuList className="d-flex h-100">
        {fields.header.topMenuHeader.menuleft.map(
          (item: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <StyledNavLink
                to={item.link.url}
                className="px-4 h-100 d-flex align-items-center"
              >
                <>
                  {isMobile ? (
                    <span>{item.linktextmobile}</span>
                  ) : (
                    <span>{item.link.title}</span>
                  )}
                </>
              </StyledNavLink>
            </li>
          )
        )}
      </TopmenuList>
      <div
        className="d-lg-flex d-none h-100 align-items-center"
        id="buttons"
        // ref={inputRef}
      >
        <ParseContent
          content={fields.header.topMenuHeader.menuright.description}
          className="d-flex align-items-center h-100"
        />
      </div>
    </div>
  )
}

const SubmenuItem = styled(motion.div)`
  @media (min-width: 992px) {
    line-height: 60px;
  }

  @media (max-width: 991px) {
    line-height: 40px;
    text-align: left;
    & > a {
      font-size: 18px !important;
      background-color: white;
    }
  }
`

interface SubmenuProps {
  items: Array<any>
}

const Submenu: React.FC<SubmenuProps> = ({ items }) => (
  <>
    {items.map((item, index) => (
      <SubmenuItem
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        initial={{
          background: '#FFFFFF',
        }}
        whileHover={{
          background: '#E9C080',
        }}
        transition={{ type: 'spring' }}
        className="w-100"
      >
        <NavLink to={item.link.url} className="d-flex w-100 ps-4">
          {item.link.title}
        </NavLink>
      </SubmenuItem>
    ))}
  </>
)

export default Header
