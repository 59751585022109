import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonPrimaryTransparentCSS = css`
  ${({ theme }) => css`
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: ${`linear-gradient(to left, rgba(${theme.color.gradient.to}, 1), rgba(${theme.color.gradient.from}, 1))`};
  `};
  display: inline-block;
  min-width: 195px;

  & > a,
  & > button {
    font-weight: ${(props) => props.theme.font.weight.semiBold} !important;
    padding: 11px 30px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    color: ${({ theme }) => theme.color.secondary};
    &:hover {
      color: ${({ theme }) => theme.color.primary} !important;
    }
  }
`

const StyledButton = styled(motion.span)`
  ${ButtonPrimaryTransparentCSS};
`

interface ButtonPrimaryTransparentProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonPrimaryTransparent: React.FC<ButtonPrimaryTransparentProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton
    className={className}
    initial={{
      background:
        'linear-gradient(90deg,rgba(233, 192, 128, 0) 0%,rgba(170, 124, 62, 0) 50%)',
    }}
    whileHover={{
      background:
        'linear-gradient(90deg,rgba(233, 192, 128, 1) 20%,rgba(170, 124, 62, 1) 80%)',
    }}
    transition={{ type: 'spring' }}
  >
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimaryTransparent
