import React from 'react'
import styled, { css } from 'styled-components'

export const TextGradientCSS = css`
  font-size: 50px;
  background-color: #e9c080;
  background-image: linear-gradient(
    360deg,
    rgba(${(props) => props.theme.color.gradient.to}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.to}, 1) 25%,
    rgba(${(props) => props.theme.color.gradient.from}, 1) 80%
  );
  background-size: 400% 300%;
  background-repeat: repeat;
  background-clip: text;
  font-family: ${({ theme }) => theme.font.family.secondary};
  -webkit-background-clip: text;
  line-height: 50px;
  -webkit-text-fill-color: transparent;
  animation: gradient 6s ease infinite;
  @media (max-width: 575px) {
    font-size: 30px;
    line-height: 30px;
  }

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 80% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`

const StyledText = styled.span`
  ${TextGradientCSS};
`

interface ButtonPrimaryProps {
  className?: string
}

const TextGradient: React.FC<ButtonPrimaryProps> = ({
  children,
  className = '',
}) => <StyledText className={className}>{children}</StyledText>

export default TextGradient
