import React from 'react'
import styled, { css } from 'styled-components'

export const BlockDefaultCSS = css`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.color.light};
    border-width: 3px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: ${`linear-gradient(to left, rgba(${theme.color.gradient.from}, 1), rgba(${theme.color.gradient.to}, 1))`};
    box-shadow: 1px 1px 12px 0px #404040;

    @media (min-width: 1400px) {
      min-height: 164px;
      min-width: 164px;
      padding: 1rem;
    }

    @media (max-width: 1399px) {
      min-height: 145px;
      min-width: 145px;
      padding: 0.75rem;
    }

    @media (max-width: 375px) {
      min-height: 0;
      min-width: 0;
    }

    & span {
      font-weight: ${theme.font.weight.bold};
      text-align: center;
      line-height: 0.8;
    }
  `};
`

const StyledBlock = styled.div<{
  noBorder: boolean
  noShadow: boolean
  noBorderRight: boolean
  noHoverEffect: boolean
}>`
  ${BlockDefaultCSS};

  ${(props) =>
    props.noBorder &&
    css`
      border: unset !important;
    `}

  ${(props) =>
    props.noShadow &&
    css`
      box-shadow: unset;
    `}

    ${(props) =>
    props.noBorder &&
    css`
      border-right: unset;
    `}

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    ${(props) =>
      props.noHoverEffect
        ? css`
            box-shadow: '';
          `
        : css`
            box-shadow: unset;
          `}
  }
`

interface BlockDefaultProps {
  className?: string
  noBorder?: boolean
  noBorderRight?: boolean
  noShadow?: boolean
  noHoverEffect?: boolean
}

const BlockDefault: React.FC<BlockDefaultProps> = ({
  children,
  className = '',
  noBorder = false,
  noShadow = false,
  noBorderRight = false,
  noHoverEffect = false,
}) => (
  <StyledBlock
    className={className}
    noBorder={noBorder}
    noShadow={noShadow}
    noBorderRight={noBorderRight}
    noHoverEffect={noHoverEffect}
  >
    {children}
  </StyledBlock>
)

export default BlockDefault
